<template>
  <div class="template-prod-container">
    <div class="template-prod-wrapper">
      <div class="template-prod-title main" :class="isMobile ? 'h7' : 'h5'">템플릿 제작 문의</div>
      <div class="partner-prod-wrapper">
        <img class="partner-prod-img" :src="prod.product.img" alt="" />
        <div class="partner-prod-info">
          <div class="partner-prod-title body5-medium main">{{ prod.product.partner.nickname }}</div>
          <div class="partner-prod-description h8 main">{{ prod.product.name }}</div>
        </div>
      </div>
      <!-- <div class="option-wrapper">
        <div class="menu-title main" :class="isMobile ? 'h7' : 'body0-bold'">제작문의 옵션을 선택해주세요.</div>
        <div class="create-options-wrapper">
          <div
            class="create-options-item"
            v-for="(option, idx) in createOptions"
            :key="`create-option-${idx}`"
            :class="{ 'selected-option': optionRadio === option.id }"
            @click="optionRadio = option.id"
          >
            <img
              :src="`/static/icon/u_radio-${optionRadio === option.id ? 'on' : 'off'}.svg`"
              alt=""
              class="unselect"
              style="width:16px;height:16px"
            />
            <div class="flex-column" style="gap:4px">
              <div class="body4 sub">
                {{ option.name }}
              </div>
              <div class="body4-bold main">
                {{ option.id === 0 ? priceFormat(prod.product.price) : '비용문의' }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div v-if="visible" class="temp-form-wrapper">
        <div class="menu-title main" :class="isMobile ? 'h7' : 'body0-bold'">
          제작하려는 플랫폼 서비스 정보를 알려주세요.
        </div>
        <div class="form-item" v-for="(form, form_idx) in formData" :key="`form-${form_idx}`">
          <div>
            <div class="inquiry-reg-title subtitle6">
              <template v-if="form.field !== 'checkedVal'">
                {{ form.label }}<span v-if="form.validate.required" class="required"> *</span>
              </template>
            </div>
            <div class="inquiry-reg-description body6 sub3" v-if="form.description">
              {{ form.description }}
            </div>
          </div>
          <div class="inquiry-reg-content">
            <lp-text-area
              v-if="form.component_name === 'LpTextArea'"
              :ref="form.field"
              v-bind.sync="form"
              :useLabel="false"
              resize
              minHeight="280px"
            />
            <component
              v-else
              :is="form.component_name"
              :ref="form.field"
              v-bind.sync="form"
              :useLabel="false"
            ></component>
          </div>
        </div>
      </div>
      <div class="user-info-container">
        <div class="menu-title main" :class="isMobile ? 'h7' : 'body0-bold'">신청하시는 고객님의 정보를 알려주세요</div>
        <div>
          <div
            :class="form.type === 'check' ? 'form-item' : 'user-form-item'"
            :style="form.field === 'email' ? { margin: '24px 0' } : ''"
            v-for="(form, form_idx) in userFormData"
            :key="`form-${form_idx}`">
            <div>
              <div class="inquiry-reg-title subtitle6">
                <template v-if="form.field !== 'checkedVal'">
                  {{ form.label }}<span v-if="form.validate.required" class="required"> *</span>
                </template>
              </div>
              <div class="inquiry-reg-description body6 sub3" v-if="form.description">
                {{ form.description }}
              </div>
            </div>
            <div class="inquiry-reg-content">
              <component
                :is="form.component_name"
                :ref="form.field"
                v-bind.sync="form"
                :useLabel="false"
              ></component>
            </div>
          </div>
        </div>
      </div>

      <div class="button-reg-container">
        <button class="button is-primary body2-medium" @click="saveData">제작문의 등록</button>
      </div>
    </div>
  </div>
</template>

<script>
import LpCheckBool from '../module/LpCheckBool';
import CheckboxGroup from '../module/CheckboxGroup';
import LpRadio from '../module/LpRadio';
import InputSingleFile from '../module/InputSingleFile';
import LpText from '../component/LpText';
import LpTextArea from '../component/LpTextArea';
import LpNumberUnit from '../component/LpNumberUnit';
import UserAPIMixin from '../../mixins/UserAPIMixin';
import FormMixin from '../../mixins/FormMixin';
import LpCheckBoxArray from '../component/LpCheckBoxArray';
import LpDropDown from '../LpDropDown';
import InputFile from '../module/InputFile';
import InputTech from '../module/InputTech';
import InputImgUploader from '../module/InputImgUploader';
import InputImgUploaderArray from '../module/InputImgUploaderArray';
import InputDatePeriod from '../module/InputDatePeriod';
import InputPrice from '../module/InputPrice';
import InputPeriod from '../module/InputPeriod';
import InputEditor from '../module/InputEditor';
import InputPhoneVerify from '../module/InputPhoneVerify';
import InputTemplateCategory from '../module/InputTemplateCategory';
import InputFaq from '../module/InputFaq';
export default {
  name: 'TemplateProdInquiry',
  mixins: [UserAPIMixin, FormMixin],
  components: {
    CheckboxGroup,
    InputImgUploader,
    InputImgUploaderArray,
    InputFile,
    LpDropDown,
    LpCheckBoxArray,
    LpNumberUnit,
    LpTextArea,
    LpText,
    InputSingleFile,
    LpRadio,
    LpCheckBool,
    InputDatePeriod,
    InputTech,
    InputPrice,
    InputPeriod,
    InputEditor,
    InputPhoneVerify,
    InputTemplateCategory,
    InputFaq,
  },
  async created() {
    if (!this.isLogin) {
      this.$store.commit('setPrevPath', this.$route.fullPath);
      this.toast('로그인이 필요합니다.');
      this.$router.push('/signin');
    } else {
      this.getTemp();
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
      this.handleResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  data() {
    return {
      formData: [],
      userFormData: [],
      visible: false,
      temp: null,
      prod: null,
      createOptions: [
        { id: 0, name: '템플릿 그대로 제작' },
        { id: 1, name: '템플릿을 바탕으로 수정 제작' },
      ],
      optionRadio: 1,
    };
  },
  methods: {
    getRecentTemp() {
      this.request.user.get(`/launchpack/template_manufacture_inquiry/recent/${this.prod.product.id}`).then((res) => {
        this.temp = res.data.inquiry;
        this.temp.files = [];
        if (this.temp) {
          this.formData.forEach((formItem) => {
            if (Object.prototype.hasOwnProperty.call(this.temp, formItem.field)) {
              formItem.value = this.temp[formItem.field];
            }
          });
        }
      });
    },
    handleResize() {
      this.formData.forEach((form) => {
        if (form.component_name.includes('input-img-')) {
          const [width, height] = this.imgRate(window.innerWidth > 768 ? 200 : 80);
          form.width = width;
          form.height = height;
        }
      });
    },
    setForm() {
      let width = '270px';
      if (window.innerWidth < 500) {
        width = '100%';
      }
      this.formData = [
        {
          label: '서비스 이름',
          placeholder: '서비스 이름을 입력해주세요.',
          maxLength: 30,
          field: 'service_name',
          type: 'text',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 50,
            min_length: 1,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '서비스 한 줄 소개',
          placeholder: '서비스를 한 줄로 소개해주세요.',
          maxLength: 100,
          field: 'simple_desc',
          type: 'text',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 100,
            min_length: 1,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '서비스 소개 및 요청사항',
          field: 'info',
          component_name: 'LpTextArea',
          value: '',
          placeholder:
            '서비스 소개와 요청사항을 구체적으로 알려주세요.\n1.서비스 소개\n2.서비스 타겟\n3.서비스 수익구조\n4.필수 기능',
          desc: '',
          maxLength: 5000,
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: '제작예산',
          placeholder: '0',
          maxLength: 30,
          field: 'budget',
          type: 'number',
          value: 0,
          component_name: 'lp-number-unit',
          unit: '만원',
          width: width,
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 1000000000000,
            min_length: 0,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '희망 소요기간',
          placeholder: '0',
          maxLength: 30,
          field: 'period',
          type: 'number',
          value: 0,
          component_name: 'lp-number-unit',
          unit: '개월',
          width: width,
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 1000,
            min_length: 0,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '참고자료',
          field: 'files',
          component_name: 'input-file',
          maxLength: 5,
          value: [],
          validate: {
            valid_required: true,
            required: false,
          },
          description: '',
          multiple: true,
        },
        {
          label: '서비스 지원환경',
          field: 'env',
          placeholder: '서비스 지원환경',
          component_name: 'LpRadio',
          block: true,
          value: 1,
          data: [
            { label: 'Mobile web 단독(PC로 접속 시 Mobile web 뷰 제공)', value: 0 },
            { label: 'PC web + Mobile web', value: 1 },
            { label: 'PC web + Mobile web + 모바일 앱', value: 2 },
            { label: '모바일 앱', value: 3 },
          ],
          validate: {
            valid_required: true,
            required: true,
          },
        },
      ];
    },
    setUserForm() {
      let checkFontSize = '15px';
      if (window.innerWidth < 500) {
        checkFontSize = '12px';
      }
      this.userFormData = [
        {
          label: '성함',
          placeholder: '성함을 입력해주세요.',
          maxLength: 30,
          field: 'name',
          type: 'text',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 30,
            min_length: 1,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '연락처',
          placeholder: '연락처를 입력해주세요.',
          maxLength: 30,
          field: 'phone',
          type: 'phone',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 30,
            min_length: 1,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '이메일',
          placeholder: '이메일을 입력해주세요.',
          maxLength: 30,
          field: 'email',
          type: 'email',
          value: '',
          component_name: 'lp-text',
          errorMsg: '',
          validate: {
            num: true,
            alpha_g: true,
            alpha_s: true,
            special: true,
            max_length: 30,
            min_length: 1,
            valid_required: true,
            required: true,
          },
        },
        {
          label: '이용약관 및 개인정보처리방침에 동의하기',
          items: [
            {
              label: '이용약관 및 개인정보처리방침에 동의하기',
              labelStyle: {
                fontSize: checkFontSize,
                lineHeight: '15px',
                color: '#555',
              },
            },
            {
              label: '(필수)',
              labelStyle: {
                fontSize: checkFontSize,
                lineHeight: '15px',
                color: '#FF6600',
              },
            },
            {
              label: '약관보기',
              link: 'term',
              labelStyle: {
                color: '#828282',
                fontSize: checkFontSize,
                textDecoration: 'underline',
                marginLeft: '8px',
              },
            },
          ],
          field: 'checkedVal',
          type: 'check',
          value: false,
          component_name: 'lp-check-box-array',
          errorMsg: '',
          validate: {
            valid_required: true,
            required: true,
          },
        },
        {
          label: '개인정보 처리 위탁에 동의',
          items: [
            {
              label: '개인정보 처리 위탁에 동의',
              labelStyle: {
                fontSize: checkFontSize,
                lineHeight: '15px',
                color: '#555',
              },
            },
            {
              label: '(필수)',
              labelStyle: {
                fontSize: checkFontSize,
                lineHeight: '15px',
                color: '#FF6600',
              },
            },
            {
              label: '약관보기',
              link: 'privacy',
              labelStyle: {
                color: '#828282',
                fontSize: checkFontSize,
                textDecoration: 'underline',
                marginLeft: '8px',
              },
            },
          ],
          field: 'checkedVal',
          type: 'check',
          value: false,
          component_name: 'lp-check-box-array',
          errorMsg: '',
          validate: {
            valid_required: true,
            required: true,
          },
        },
      ];
      let name = '';
      let email = '';
      let phone = '';
      let user = this.cloneItem(this.$store.getters.user);
      name = user.name;
      email = user.email;
      phone = user.phone;
      this.userFormData.forEach((item) => {
        if (item.field === 'name') {
          item.value = name;
        } else if (item.field === 'email') {
          item.value = email;
        } else if (item.field === 'phone') {
          item.value = phone;
        }
      });
    },
    getTemp() {
      if (!this.$store.getters.productInquiry) {
        this.toast('조회된 템플릿이 없습니다.');
        this.$router.go(-1);
      } else {
        this.prod = this.$store.getters.productInquiry;
        this.optionRadio = this.prod.option === 2 ? 1 : this.prod.option;
        this.setForm();
        this.setUserForm();
        this.visible = true;
        this.getRecentTemp();
      }
    },
    priceFormat(price) {
      let result = this.currency(price.sale_price);
      if (price.price_type === 0) {
        if (price.won_type === 1) {
          result += '~';
        } else if (price.won_type === 2) {
          result += ' 초반대';
        } else if (price.won_type === 3) {
          result += ' 후반대';
        }
      } else {
        result = price.price_text;
      }
      return result;
    },
    saveData() {
      let result = this.getParamData(this.formData);
      if (!result.enable) {
        return;
      }
      if (this.userFormData.length > 0) {
        let result2 = this.getParamData(this.userFormData);
        if (!result2.enable) {
          return;
        }
        result.params.name = result2.params.name;
        result.params.email = result2.params.email;
        result.params.phone = result2.params.phone;
        result.params.option = this.optionRadio;
        result.params.product_id = this.prod.product.id;
        result.params.user_id = this.user.user_id;
        result.params.status = 0;
      }

      this.setLoading('등록중입니다.');
      this.request.user
        .post('/launchpack/template_manufacture_inquiry', result.params)
        .then((res) => {
          this.clearLoading();
          if (res.status === 200) {
            this.$router.replace(`template_prod_inquiry_done`);
          }
        })
        .catch((err) => {
          this.clearLoading();
          if (err.response.data.message) {
            this.toast(err.response.data.message);
          } else if (err.response.data.detail) {
            this.toast(err.response.data.detail);
          } else {
            this.toast('오류가 발생하였습니다. 관리자에게 문의주세요.');
          }
        });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.template-prod-container
  display flex
  flex-direction column
  margin-top 64px
  padding-bottom 80px
.template-prod-wrapper
  width 100%
  max-width 1152px
  margin 0 auto
.template-prod-title
  padding 40px 0

.partner-prod-wrapper
  display grid
  grid-template-columns 80px 1fr
  align-items center
  gap 16px
  padding-bottom 40px

.partner-prod-img
  width 80px
  height 100px
  border-radius 8px
  border: 1px solid #EEE;
  object-fit cover

.option-wrapper
  display flex
  flex-direction column
  gap 12px
  padding-bottom 60px
  border-top 1px solid #242428

.create-options-wrapper
  display grid
  grid-template-columns 1fr 1fr
  gap 12px

.create-options-item
  display grid
  grid-template-columns 16px 1fr
  align-items start
  padding 12px 16px
  gap 8px
  border-radius 8px
  border 1px solid $gray1
  cursor pointer
  &.selected-option
    border 2px solid $primary
    padding 11px 15px

.menu-title
  padding 16px 0

.temp-form-wrapper
  display flex
  flex-direction column
  gap 24px
  padding-bottom 60px
  border-top 1px solid #242428

.template-create-container
  padding 64px 0
  background-color $gray3

.template-title
  font-size 22px
  font-weight 700
  padding-bottom 40px

.container
  background-color white
  border-radius 16px
  padding 40px
  margin 40px auto

.form-item
  display flex
  flex-direction column
  gap 8px

.user-form-item
  display flex
  flex-direction column
  gap 8px
  margin-top 24px

.inquiry-reg-title
  width fit-content
.inquiry-reg-content
  flex 1

.required
  color $error

.user-info-container
  display flex
  flex-direction column
  padding-bottom 60px
  border-top 1px solid #242428

.button-reg-container
  display flex
  justify-content center
  flex-wrap wrap
  gap 10px
  margin-top 40px

.button-reg-container button
  width 242px
  height 48px

.reject
  padding 12px
  border-radius 8px
  background-color $error50
  color $error
  margin-bottom 40px

@media (max-width: 600px)
  .template-prod-container
    padding 0 16px 40px
    margin-top 60px
  .template-prod-title
    padding 24px 0
  .create-options-wrapper
    grid-template-columns 1fr
  .button-reg-container
    margin-top 0px
  .partner-prod-wrapper
    grid-template-columns 60px 1fr
  .partner-prod-img
    width 60px
    height 75px
  .container
    margin 16px 0
    padding 16px
  .template-title
    font-size 18px
  .form-item
    flex-direction column
    .inquiry-reg-title
      padding 0
      width 100%
</style>
