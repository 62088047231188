<template>
  <div class="c-input-editor">
    <textarea id="editor"></textarea>
    <div class="lp-error-msg" v-if="validate && validate.error_msg && validate.error_msg !== ''">{{ validate.error_msg }}</div>
  </div>
</template>

<script>
  import suneditor from 'suneditor'
  import plugins from 'suneditor/src/plugins'
  import lang from 'suneditor/src/lang'

  export default {
    name: "InputEditor",
    props: {
      text: {
        type: String
      },
      height: {
        type: Number,
        default: 500
      },
      value: {
        type: String
      },
      validate: {
        type: Object
      },
      placeholder: {
        type: String
      }
    },
    mounted() {
      this.editor = suneditor.create('editor', {
        plugins: plugins,
        placeholder: this.placeholder,
        buttonList: [
          [ 'font', 'fontSize'], ['fontColor', 'hiliteColor'],
          ['bold', 'italic'], ['underline', 'strike'],
          ['align', 'outdent', 'indent'],
          ['list', 'table', 'codeView' ],
          ['horizontalRule', 'video', 'image', 'link'],
        ],
        font: [
          "Noto Sans Korean",
          "Inter"
        ],
        fontSize: [
          10,11,12,13,14,15,16,17,18,19,20,22,24,26,28,36,48,72
        ],
        lang: lang.ko,
        width: '100%',
        height: 'auto',
        minHeight: '200px',
        defaultStyle: 'font-size:14px',
        resizingBar: false
      });

      this.$nextTick(()=>{
        let list = document.getElementsByClassName('se-btn-list');
        for(let i=0; i<list.length; i++) {
          let item = list[i];
          let titleKor = {
            'Noto Sans Korean': '노토 산스',
            'Inter': 'Inter'
            
          };
          let title = titleKor[item.title];
          if(title) {
            item.title = title;
            item.innerText = title;
          }
        }
        let tray = this.$el.getElementsByClassName('se-btn-tray')[0];
        let desc = document.createElement('div');
        desc.innerHTML = `<div class="unselect" style="color#4f4f4f;padding:8px 4px;font-size:12px;">*각 이미지 용량 최대 5MB, 가로 1000px, 세로 10000px까지 업로드 가능합니다.</div>`;
        tray.appendChild(desc);

        this.editor.setContents(this.value);

        let el = document.getElementsByClassName('se-wrapper-inner')[0];
        el.blur();
      });

      this.editor.onChange = (e) => {
        this.$emit('update:value', e);
      };
    },
    data() {
      return {
        editor: undefined
      }
    },
    methods: {
      chkValid() {
        if(this.validate && this.validate.valid_required) {
          if (this.value === '' || this.value === '<p><br></p>') {
            this.validate.error_msg = '내용을 작성해주세요';
          } else {
            this.validate.error_msg = '';
          }
        }
      },
    },
    computed: {
      
    },
    watch: {
      value(n,o) {
        if(o === '' && n !== '' && this.editor.getContents() !== n) {
          this.editor.setContents(n);
        }
        this.chkValid();
      }
    }
  }
</script>

<style lang="stylus">
  .sun-editor
    border 1px solid #f1f1f1 !important

  .e-editor img
    max-width 100%
    display inline

  .sun-editor .se-toolbar
    padding 0
    outline none
  .sun-editor .se-btn-tray
    padding 16px

  .sun-editor-editable table td.se-table-selected-cell
    border 1px solid #e1e1e1
    background transparent
  .sun-editor-editable td p
    margin 0

  .sun-editor-editable .se-component.__se__float-left
  .sun-editor-editable .se-component.__se__float-right
    margin 0

  .sun-editor .se-list-layer.se-list-line
    width 125px

  .sun-editor .se-list-layer
    max-height 300px
    overflow-y auto

  .sun-editor .se-wrapper
    border-top 1px solid #e3e3e3
  .lp-error-msg
    color #e80000
    font-size 0.7rem
    margin-top 4px
    margin-bottom 12px
    width: 100%
</style>
